import React from "react"
import { PropTypes } from "prop-types"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from "victory"
import LinearProgress from '@mui/material/LinearProgress';

class BarChart extends React.Component {

    render() {
        const { data, horizontal, displayMode } = this.props

        const contrastColor = displayMode && displayMode === "dark" ? "white" : "black"

        return (
            <div>
                {data ? (
                    horizontal ? (
                        <VictoryChart
                            //trial and error led to this function to calulate the max height
                            height={data.length * 20 + 50}
                            //padding so one can see the axis and they dont clip outside the container
                            padding={{
                                top: 20,
                                bottom: 20,
                                left: 10,
                                right: 10,
                            }}
                            //distance between data points
                            domainPadding={{ x: 12, y: 0 }}
                            //min max value of axis
                            domain={{ y: [40, 160] }}
                        >
                            <VictoryAxis
                                style={{
                                    axis: { stroke: "transparent" },
                                    grid: { strokeOpacity: "0%" },
                                    tickLabels: { fill: "transparent" },
                                    ticks: { stroke: "transparent" },
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    axis: { stroke: "transparent" },
                                    grid: {
                                        stroke: contrastColor,
                                        strokeOpacity: "100%",
                                    },
                                    tickLabels: {
                                        fontSize: "10",
                                        fill: contrastColor,
                                    },
                                    ticks: { stroke: "transparent" },
                                }}
                            />
                            <VictoryBar
                                data={data}
                                horizontal
                                barWidth={19}
                                //rounded corners of bars
                                cornerRadius={2}
                                style={{
                                    data: {
                                        fill: ({ datum }) => datum.color,
                                    },
                                    labels: {
                                        fill: "white",
                                        offsetAnchor: "start",
                                        fontSize: "10",
                                    },
                                }}
                                labels={({ datum }) => datum.x}
                                labelComponent={
                                    //labels should all start at the same x-value to look better
                                    <VictoryLabel x={20} />
                                }
                            />
                        </VictoryChart>
                    ) : (
                        <VictoryChart
                            height={170}
                            //padding so one can see the axis and they dont clip outside the container
                            padding={{
                                top: 20,
                                bottom: 20,
                                left: 20,
                                right: 10,
                            }}
                            //distance between data points
                            domainPadding={{ x: 50, y: 0 }}
                            domain={{ y: [40, 160] }}
                        >
                            <VictoryAxis
                                style={{
                                    //dont display this axis
                                    axis: { stroke: "transparent" },
                                    grid: { strokeOpacity: "0%" },
                                    tickLabels: { fill: "transparent" },
                                    ticks: { stroke: "transparent" },
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    //show gris lines of the depenent axis for better readability
                                    axis: { stroke: "transparent" },
                                    grid: {
                                        stroke: contrastColor,
                                        strokeOpacity: "100%",
                                    },
                                    tickLabels: {
                                        fontSize: "10",
                                        fill: contrastColor,
                                    },
                                    ticks: { stroke: "transparent" },
                                }}
                            />
                            <VictoryBar
                                data={data}
                                labels={(d) => d.y}
                                barWidth={95}
                                cornerRadius={2}
                                style={{
                                    data: {
                                        fill: ({ datum }) => datum.color,
                                    },
                                    labels: {
                                        fill: "white",
                                        offsetAnchor: "start",
                                        fontSize: "10",
                                    },
                                }}
                                //labels={({ datum }) => datum.x}
                                labelComponent={
                                    //labels should all start at the same y-value to look better
                                    <VictoryLabel y={150} />
                                }
                            />
                        </VictoryChart>
                    )
                ) : (
                    <LinearProgress />
                )}
            </div>
        )
    }
}

BarChart.propTypes = {
    //data to be displayed
    data: PropTypes.array,

    horizontal: PropTypes.bool,
}

export default BarChart
