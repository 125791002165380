import './App.css';
import {useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Paper from '@mui/material/Paper';
import BarChart from "./chart/BarChart";
import {Grid, Link, Typography} from "@mui/material";
import Image from "mui-image";
import * as PropTypes from "prop-types";
import { useCallback } from 'react'
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Trans, useTranslation } from 'react-i18next';
import enTranslation from "./en.json";
import deTranslation from "./de.json";
import MyLink from "./MyLink";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            en: enTranslation,
            de: deTranslation
        },
        // if you're using a language detector, do not define the lng option
        fallbackLng: "de",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        detection: {
            lookupQuerystring: 'lang',
        }
    });

const minTextWords = 4


function Item(props) {
    return null;
}

Item.propTypes = {children: PropTypes.node};

function App() {
    const { t } = useTranslation();
    const [error, setError] = useState(0)
    const [data, setData] = useState(null)
    const [chartData, setChartData] = useState([])

    const [searchParams] = useSearchParams();
    const text = decodeURIComponent(escape(atob(searchParams.get("t"))));
    const textLength = text.split(' ').length

    const Auswertung_1 = t('empathie');
    const Auswertung_2 = t('rationalitaet');
    const Auswertung_3 = t('zugehoerigkeit');
    const Auswertung_4 = t('annerkennung');
    const Auswertung_5 = t('sicherheit');
    const Auswertung_6 = t('durchsetzung');

    let length = textLength > 150 ? "ersten 150" : textLength;
    let lng = i18n.language === "de" ? "" : "en";
    const delay = retryCount =>
        new Promise(resolve => {
            console.log(retryCount)
            if( retryCount >= 5){
                setError(1)
                return Promise.reject('maximum retryCount reached: fetch cancelled');
            } else {
                setTimeout(resolve, 10 ** retryCount)
            }
        });

    const getAnalysis = useCallback((retryCount = 0) => {
        fetch('https://bookmarklet.senseaition.com/analyze', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: text,
        })
            .then((res) => res.json())
            .then((result) => {

                let chartDataObj = [], twentyfive = []
                chartDataObj["motivation"] = []

                result.map(item => {
                    if (item.type === "twentyfive" || item.type === "SideKickInfo") {
                        item.annotations.map(annotation => {
                            if (annotation.value === "Motivation") {
                                annotation.vector.map(value => {
                                    twentyfive[value.name] = value.nvalue
                                    return null
                                })
                            } else if (annotation.value === "SideKickInfo") {
                                annotation.vector.map(value => {
                                    twentyfive[value.name] = value.svalue
                                    return null
                                })
                            }
                            return null
                        })
                    }
                    return null
                })

                chartDataObj["motivation"].push({
                    x: Auswertung_1,
                    y: twentyfive["white"],
                    color: "#90a4ae",
                });

                chartDataObj["motivation"].push({
                    x: Auswertung_2,
                    y: twentyfive["black"],
                    color: "#212121",
                });

                chartDataObj["motivation"].push({
                    x: Auswertung_3,
                    y: twentyfive["yellow"],
                    color: "#ffa000",
                });

                chartDataObj["motivation"].push({
                    x: Auswertung_4,
                    y: twentyfive["blue"],
                    color: "#1e88e5",
                });

                chartDataObj["motivation"].push({
                    x: Auswertung_5,
                    y: twentyfive["green"],
                    color: "#4caf50",
                });

                chartDataObj["motivation"].push({
                    x: Auswertung_6,
                    y: twentyfive["red"],
                    color: "#f44336",
                });

                setChartData(chartDataObj);
                setData({Type: twentyfive["Type"], DocRef: twentyfive["DocRef"]});
            }).catch(() => delay(retryCount).then(() => getAnalysis(retryCount + 1)));
    }, [text, Auswertung_1, Auswertung_2, Auswertung_3, Auswertung_4, Auswertung_5, Auswertung_6]);


    useEffect(() => {
        if (data === null && textLength > minTextWords) {
            // start the analysis call with exponential backoff
            getAnalysis(0);
        }
    }, [text, data, chartData, textLength, error, getAnalysis])
    //const link = "https://doc.senseaition.com" + lng + "kommunikation/" + data.Type.toString().replaceAll("ü", "ue");
    const appPaperBox = useRef(null);
    useOutsideAlerter(appPaperBox);

    //<img src={logo} className="Bookmarklet-logo" alt="logo"/>

    /*
    <TextField
                        className="Bookmarklet-text"
                        multiline
                        rows={4}
                        disabled={true}
                        defaultValue={text === "" ? "Bitte einen Text mit dem Mauszeiger auswählen." : text}
                    />
     */

    return (
        <div className="Bookmarklet-background">
            <Paper className="Bookmarklet" elevation={24} ref={appPaperBox}>
                {text === "" ? (
                    <Typography variant="h3" gutterBottom component="div">
                        {t('q1')}
                    </Typography>
                ) : textLength > 1 && textLength <= minTextWords ? (
                    <Typography variant="h3" gutterBottom component="div">
                        {t('q2', { minTextWords: minTextWords })}
                    </Typography>
                ) : data !== null ? (
                    <div>
                        <Typography variant="h3" gutterBottom component="div">
                            Audienca
                        </Typography>
                        <Grid container spacing={2} className={"Bookmarklet-text"}>
                            <Grid item>
                                <Image zerominwidth={"true"} height={"20vmin"} width={"20vmin"} fit={"contain"} src={"/25_" + data.Type.toString().replaceAll("ü", "ue") + ".png"}/>
                            </Grid>
                            <Grid item xs>
                                <Grid container
                                      direction="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      className={"Bookmarklet-feedback"}
                                >
                                    <Typography align="justify" variant="subtitle1" component="div">
                                        {t("analyzed_text_type")}<MyLink type = {data.Type} lang={lng}/> {t("analyzed_text_type2")}
                                    </Typography>
                                    <Typography align="justify" variant="subtitle1" component="div">
                                        <Trans i18nKey="not_desired_text">
                                            War das nicht gewünscht? <Link target="_blank" href="https://doc.senseaition.com/kommunikation/">Hier</Link> finden Sie die kommunikativen Bedürfnisse <Link target="_blank" href="https://doc.senseaition.com/kommunikation/">anderer Persönlichkeitstypen</Link> - als Hilfe für eine Verbesserung des Textes.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                ) : error === 0 ? (
                    <Typography variant="h3" gutterBottom component="div">
                        {t('waiting')}
                    </Typography>
                ) : error === 1 ? (
                    <Typography variant="h3" gutterBottom component="div">
                        {t('not_avaiable')}
                    </Typography>
                ) : (
                    <Typography variant="h3" gutterBottom component="div">
                        {t('error')}
                    </Typography>
                )
                }

                {chartData && text !== "" && error === 0 && textLength > minTextWords && (
                    <div className="Bookmarklet-diagram">
                        <BarChart
                            horizontal
                            data={chartData["motivation"]}
                        />
                    </div>

                )}
                {textLength > minTextWords && (
                    <div className="Bookmarklet-footer">
                        <Grid container
                              direction="column"
                              justifyContent="center"
                              alignItems="center">
                            <Grid container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center">
                                <Typography variant="subtitle1" component="div">
                                    {t('twenty_five_analysis', { length: length})}
                                </Typography>
                                <Link target="_blank" href="https://senseaition.com"><img src={"/logo_400.png"} className="Bookmarklet-logo" alt="logo"/></Link>
                            </Grid>
                            <Typography variant="subtitle1" component="div">
                                {t('learn_more')} <Link target="_blank" href="https://doc.senseaition.com/"> TwentyFive :: TwentyFive - Knowledge Base (senseaition.com)</Link>
                            </Typography>
                        </Grid>
                    </div>
                )}
            </Paper>
        </div>
    );
}

export default App;

function useOutsideAlerter(ref) {
    useEffect(() => {

        // Function for click event
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (window && window.parent) {
                    window.parent.postMessage({message: 'exitBookmarklet'}, '*');
                }
            }
        }

        // Adding click event listener
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);
}
