import React from "react";

export default function MyLink({ type, lang }) {
    const typeSmall = type.toLowerCase();
    const lng = lang === "de" ? "" : "en/";
    const link = `https://doc.senseaition.com/${lng}kommunikation/${typeSmall}`;
    return (
        <a target="_blank" rel="noopener noreferrer"href={link}>
            {type}
        </a>
    );
}